export const countries = [
  {
    name: 'france',
    iso_code: 'FR',
    phone_country: '+33',
    flag: '/flags/france.svg',
    max: 9,
    min: 9
  },
  {
    name: 'germany',
    iso_code: 'DE',
    phone_country: '+49',
    flag: '/flags/germany.svg',
    max: 11,
    min: 11
  },
  {
    name: 'italy',
    iso_code: 'IT',
    phone_country: '+39',
    flag: '/flags/italy.svg',
    max: 10,
    min: 10
  },
  {
    name: 'spain',
    iso_code: 'ES',
    phone_country: '+34',
    flag: '/flags/spain.svg',
    max: 9,
    min: 9
  },
  {
    name: 'united_states_of_america',
    iso_code: 'US',
    phone_country: '+1',
    flag: '/flags/united states.svg',
    max: 10,
    min: 10
  },
  {
    name: 'united_kingdom',
    iso_code: 'GB',
    phone_country: '+44',
    flag: '/flags/united kingdom.svg',
    max: 10,
    min: 10
  },
  {
    name: 'united_arab_emirates',
    iso_code: 'AE',
    phone_country: '+971',
    flag: '/flags/united arab emirates.svg',
    max: 9,
    min: 9
  },
  {
    name: 'sweden',
    iso_code: 'SE',
    phone_country: '+46',
    flag: '/flags/sweden.svg',
    max: 9,
    min: 10
  },
  {
    name: 'saudi_arabia',
    iso_code: 'SA',
    phone_country: '+966',
    flag: '/flags/saudi arabia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'afghanistan',
    iso_code: 'AF',
    phone_country: '+93',
    flag: '/flags/afghanistan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'albania',
    iso_code: 'AL',
    phone_country: '+355',
    flag: '/flags/albania.svg',
    max: 9,
    min: 3
  },
  {
    name: 'andorra',
    iso_code: 'AD',
    phone_country: '+376',
    flag: '/flags/andorra.svg',
    max: 9,
    min: 9
  },
  {
    name: 'angola',
    iso_code: 'AO',
    phone_country: '+244',
    flag: '/flags/angola.svg',
    max: 9,
    min: 9
  },
  {
    name: 'antigua_and_barbuda',
    iso_code: 'AG',
    phone_country: '+1-268',
    flag: '/flags/antigua and barbuda.svg',
    max: 10,
    min: 10
  },
  {
    name: 'algeria',
    iso_code: 'DZ',
    phone_country: '+213',
    flag: '/flags/Algeria.svg',
    max: 9,
    min: 9
  },
  {
    name: 'argentina',
    iso_code: 'AR',
    phone_country: '+54',
    flag: '/flags/argentina.svg',
    max: 10,
    min: 10
  },
  {
    name: 'armenia',
    iso_code: 'AM',
    phone_country: '+374',
    flag: '/flags/armenia.svg',
    max: 8,
    min: 8
  },
  {
    name: 'aruba',
    iso_code: 'AW',
    phone_country: '+297',
    flag: '/flags/aruba.svg',
    max: 7,
    min: 7
  },
  {
    name: 'australia',
    iso_code: 'AU',
    phone_country: '+61',
    flag: '/flags/australia.svg',
    max: 15,
    min: 5
  },
  {
    name: 'austria',
    iso_code: 'AT',
    phone_country: '+43',
    flag: '/flags/austria.svg',
    max: 13,
    min: 4
  },
  {
    name: 'azerbaijan',
    iso_code: 'AZ',
    phone_country: '+994',
    flag: '/flags/azerbaijan.svg',
    max: 9,
    min: 8
  },
  {
    name: 'belgium',
    iso_code: 'BE',
    phone_country: '+32',
    flag: '/flags/belgium.svg',
    max: 9,
    min: 9
  },
  {
    name: 'bahamas',
    iso_code: 'BS',
    phone_country: '+1-242',
    flag: '/flags/bahamas.svg',
    max: 10,
    min: 10
  },
  {
    name: 'bahrain',
    iso_code: 'BH',
    phone_country: '+973',
    flag: '/flags/bahrain.svg',
    max: 8,
    min: 8
  },
  {
    name: 'bangladesh',
    iso_code: 'BD',
    phone_country: '+880',
    flag: '/flags/bangladesh.svg',
    max: 10,
    min: 6
  },
  {
    name: 'barbados',
    iso_code: 'BB',
    phone_country: '+1-246',
    flag: '/flags/barbados.svg',
    max: 10,
    min: 10
  },
  {
    name: 'belize',
    iso_code: 'BZ',
    phone_country: '+501',
    flag: '/flags/belize.svg',
    max: 7,
    min: 7
  },
  {
    name: 'benin',
    iso_code: 'BJ',
    phone_country: '+229',
    flag: '/flags/benin.svg',
    max: 8,
    min: 8
  },
  {
    name: 'bhutan',
    iso_code: 'BT',
    phone_country: '+975',
    flag: '/flags/bhutan.svg',
    max: 8,
    min: 7
  },
  {
    name: 'belarus',
    iso_code: 'BY',
    phone_country: '+375',
    flag: '/flags/belarus.svg',
    max: 10,
    min: 9
  },
  {
    name: 'bolivia',
    iso_code: 'BO',
    phone_country: '+591',
    flag: '/flags/bolivia.svg',
    max: 8,
    min: 8
  },
  {
    name: 'bosnia_and_herzegovina',
    iso_code: 'BA',
    phone_country: '+387',
    flag: '/flags/bosnia and herzegovina.svg',
    max: 8,
    min: 8
  },
  {
    name: 'botswana',
    iso_code: 'BW',
    phone_country: '+267',
    flag: '/flags/botswana.svg',
    max: 8,
    min: 7
  },
  {
    name: 'brazil',
    iso_code: 'BR',
    phone_country: '+55',
    flag: '/flags/brazil.svg',
    max: 10,
    min: 10
  },
  {
    name: 'brunei',
    iso_code: 'BN',
    phone_country: '+673',
    flag: '/flags/brunei.svg',
    max: 7,
    min: 7
  },
  {
    name: 'bulgaria',
    iso_code: 'BG',
    phone_country: '+359',
    flag: '/flags/bulgaria.svg',
    max: 9,
    min: 9
  },
  {
    name: 'burkina_faso',
    iso_code: 'BF',
    phone_country: '+226',
    flag: '/flags/burkina faso.svg',
    max: 8,
    min: 8
  },
  {
    name: 'burundi',
    iso_code: 'BI',
    phone_country: '+257',
    flag: '/flags/burundi.svg',
    max: 8,
    min: 8
  },
  {
    name: 'cape_verde',
    iso_code: 'CV',
    phone_country: '+238',
    flag: '/flags/cape verde.svg',
    max: 7,
    min: 7
  },
  {
    name: 'cambodia',
    iso_code: 'KH',
    phone_country: '+855',
    flag: '/flags/cambodia.svg',
  },
  {
    name: 'cameroon',
    iso_code: 'CM',
    phone_country: '+237',
    flag: '/flags/cameroon.svg',
    max: 8,
    min: 8
  },
  {
    name: 'canada',
    iso_code: 'CA',
    phone_country: '+1',
    flag: '/flags/canada.svg',
    max: 10,
    min: 10
  },
  {
    name: 'chad',
    iso_code: 'TD',
    phone_country: '+235',
    flag: '/flags/chad.svg',
    max: 8,
    min: 8
  },
  {
    name: 'chile',
    iso_code: 'CL',
    phone_country: '+56',
    flag: '/flags/chile.svg',
    max: 9,
    min: 8
  },
  {
    name: 'china',
    iso_code: 'CN',
    phone_country: '+86',
    flag: '/flags/china.svg',
    max: 12,
    min: 5
  },
  {
    name: 'cyprus',
    iso_code: 'CY',
    phone_country: '+357',
    flag: '/flags/cyprus.svg',
    max: 11,
    min: 8
  },
  {
    name: 'vatican_city',
    iso_code: 'VA',
    phone_country: '+379',
    flag: '/flags/vatican city.svg',
    max: 11,
    min: 11
  },
  {
    name: 'colombia',
    iso_code: 'CO',
    phone_country: '+57',
    flag: '/flags/colombia.svg',
    max: 10,
    min: 8
  },
  {
    name: 'comoros',
    iso_code: 'KM',
    phone_country: '+269',
    flag: '/flags/comoros.svg',
    max: 7,
    min: 7
  },
  {
    name: 'republic_of_the_congo',
    iso_code: 'CG',
    phone_country: '+242',
    flag: '/flags/republic of the congo.svg',
    max: 9,
    min: 5
  },
  {
    name: 'north_korea',
    iso_code: 'KP',
    phone_country: '+850',
    flag: '/flags/north korea.svg',
    max: 17,
    min: 6
  },
  {
    name: 'south_korea',
    iso_code: 'KR',
    phone_country: '+82',
    flag: '/flags/south korea.svg',
    max: 11,
    min: 8
  },
  {
    name: 'ivory_coast',
    iso_code: 'CI',
    phone_country: '+225',
    flag: '/flags/ivory coast.svg',
    max: 8,
    min: 8
  },
  {
    name: 'costa_rica',
    iso_code: 'CR',
    phone_country: '+506',
    flag: '/flags/costa rica.svg',
    max: 8,
    min: 8
  },
  {
    name: 'croatia',
    iso_code: 'HR',
    phone_country: '+385',
    flag: '/flags/croatia.svg',
    max: 12,
    min: 8
  },
  {
    name: 'cuba',
    iso_code: 'CU',
    phone_country: '+53',
    flag: '/flags/cuba.svg',
    max: 8,
    min: 6
  },
  {
    name: 'curaçao',
    iso_code: 'CW',
    phone_country: '+599',
    flag: '/flags/curacao.svg',
    max: 8,
    min: 7
  },
  {
    name: 'denmark',
    iso_code: 'DK',
    phone_country: '+45',
    flag: '/flags/denmark.svg',
    max: 8,
    min: 8
  },
  {
    name: 'dominica',
    iso_code: 'DM',
    phone_country: '+1-767',
    flag: '/flags/dominica.svg',
    max: 10,
    min: 10
  },
  {
    name: 'ecuador',
    iso_code: 'EC',
    phone_country: '+593',
    flag: '/flags/ecuador.svg',
    max: 8,
    min: 8
  },
  {
    name: 'egypt',
    iso_code: 'EG',
    phone_country: '+20',
    flag: '/flags/egypt.svg',
    max: 9,
    min: 7
  },
  {
    name: 'el_salvador',
    iso_code: 'SV',
    phone_country: '+503',
    flag: '/flags/el salvador.svg',
    max: 11,
    min: 7
  },
  {
    name: 'eritrea',
    iso_code: 'ER',
    phone_country: '+291',
    flag: '/flags/eritrea.svg',
    max: 7,
    min: 7
  },
  {
    name: 'slovakia',
    iso_code: 'SK',
    phone_country: '+421',
    flag: '/flags/slovakia.svg',
    max: 9,
    min: 4
  },
  {
    name: 'slovenia',
    iso_code: 'YES',
    phone_country: '+386',
    flag: '/flags/slovenia.svg',
    max: 8,
    min: 8
  },
  {
    name: 'estonia',
    iso_code: 'EE',
    phone_country: '+372',
    flag: '/flags/estonia.svg',
    max: 10,
    min: 7
  },
  {
    name: 'ethiopia',
    iso_code: 'ET',
    phone_country: '+251',
    flag: '/flags/ethiopia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'philippines',
    iso_code: 'PH',
    phone_country: '+63',
    flag: '/flags/philippines.svg',
    max: 10,
    min: 8
  },
  {
    name: 'finland',
    iso_code: 'FI',
    phone_country: '+358',
    flag: '/flags/finland.svg',
    max: 12,
    min: 5
  },
  {
    name: 'fiji',
    iso_code: 'FJ',
    phone_country: '+679',
    flag: '/flags/fiji.svg',
    max: 7,
    min: 7
  },
  {
    name: 'gabon',
    iso_code: 'GA',
    phone_country: '+241',
    flag: '/flags/gabon.svg',
    max: 7,
    min: 6
  },
  {
    name: 'gambia',
    iso_code: 'GM',
    phone_country: '+220',
    flag: '/flags/gambia.svg',
    max: 7,
    min: 7
  },
  {
    name: 'georgia',
    iso_code: 'GE',
    phone_country: '+995',
    flag: '/flags/georgia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'ghana',
    iso_code: 'GH',
    phone_country: '+233',
    flag: '/flags/ghana.svg',
    max: 9,
    min: 5
  },
  {
    name: 'gibraltar',
    iso_code: 'GI',
    phone_country: '+350',
    flag: '/flags/gibraltar.svg',
    max: 8,
    min: 8
  },
  {
    name: 'greece',
    iso_code: 'GR',
    phone_country: '+30',
    flag: '/flags/greece.svg',
    max: 10,
    min: 10
  },
  {
    name: 'greenland',
    iso_code: 'GL',
    phone_country: '+299',
    flag: '/flags/greenland.svg',
    max: 6,
    min: 6
  },
  {
    name: 'guam',
    iso_code: 'GU',
    phone_country: '+1-671',
    flag: '/flags/guam.svg',
    max: 10,
    min: 10
  },
  {
    name: 'guatemala',
    iso_code: 'GT',
    phone_country: '+502',
    flag: '/flags/guatemala.svg',
    max: 8,
    min: 8
  },
  {
    name: 'guernsey',
    iso_code: 'GG',
    phone_country: '+44-1481',
    flag: '/flags/guernsey.svg',
  },
  {
    name: 'guinea',
    iso_code: 'GN',
    phone_country: '+224',
    flag: '/flags/guinea.svg',
    max: 8,
    min: 8
  },
  {
    name: 'equatorial_guinea',
    iso_code: 'GQ',
    phone_country: '+240',
    flag: '/flags/equatorial guinea.svg',
    max: 9,
    min: 9
  },
  {
    name: 'guinea_bissau',
    iso_code: 'GW',
    phone_country: '+245',
    flag: '/flags/guinea bissau.svg',
  },
  {
    name: 'guyana',
    iso_code: 'GY',
    phone_country: '+592',
    flag: '/flags/guyana.svg',
    max: 7,
    min: 7
  },
  {
    name: 'haiti',
    iso_code: 'HT',
    phone_country: '+509',
    flag: '/flags/haiti.svg',
    max: 8,
    min: 8
  },
  {
    name: 'honduras',
    iso_code: 'HN',
    phone_country: '+504',
    flag: '/flags/honduras.svg',
    max: 8,
    min: 8
  },
  {
    name: 'hong_kong',
    iso_code: 'HK',
    phone_country: '+852',
    flag: '/flags/hong kong.svg',
    max: 9,
    min: 4
  },
  {
    name: 'hungary',
    iso_code: 'HU',
    phone_country: '+36',
    flag: '/flags/hungary.svg',
    max: 9,
    min: 8
  },
  {
    name: 'india',
    iso_code: 'IN',
    phone_country: '+91',
    flag: '/flags/india.svg',
    max: 10,
    min: 7
  },
  {
    name: 'indonesia',
    iso_code: 'ID',
    phone_country: '+62',
    flag: '/flags/indonesia.svg',
    max: 10,
    min: 5
  },
  {
    name: 'iran',
    iso_code: 'IR',
    phone_country: '+98',
    flag: '/flags/iran.svg',
    max: 10,
    min: 6
  },
  {
    name: 'iraq',
    iso_code: 'IQ',
    phone_country: '+964',
    flag: '/flags/iraq.svg',
    max: 10,
    min: 8
  },
  {
    name: 'ireland',
    iso_code: 'IE',
    phone_country: '+353',
    flag: '/flags/ireland.svg',
    max: 11,
    min: 7
  },
  {
    name: 'isle_of_man',
    iso_code: 'IM',
    phone_country: '+44-1624',
    flag: '/flags/isle of man.svg',
  },
  {
    name: 'norfolk_island',
    iso_code: 'NF',
    phone_country: '+672',
    flag: '/flags/norfolk island.svg',
  },
  {
    name: 'iceland',
    iso_code: 'IS',
    phone_country: '+354',
    flag: '/flags/iceland.svg',
    max: 9,
    min: 7
  },
  {
    name: 'bermuda_islands',
    iso_code: 'BM',
    phone_country: '+1-441',
    flag: '/flags/bermuda.svg',
    max: 10,
    min: 10
  },
  {
    name: 'cayman_islands',
    iso_code: 'KY',
    phone_country: '+1-345',
    flag: '/flags/cayman islands.svg',
    max: 10,
    min: 10
  },
  {
    name: 'cocos_keeling_islands',
    iso_code: 'CC',
    phone_country: '+61',
    flag: '/flags/cocos island.svg',
  },
  {
    name: 'cook_islands',
    iso_code: 'CK',
    phone_country: '+682',
    flag: '/flags/cook islands.svg',
    max: 5,
    min: 5
  },
  {
    name: 'faroe_islands',
    iso_code: 'FO',
    phone_country: '+298',
    flag: '/flags/faroe islands.svg',
    max: 6,
    min: 6
  },
  {
    name: 'maldives_islands',
    iso_code: 'MV',
    phone_country: '+960',
    flag: '/flags/maldives.svg',
    max: 7,
    min: 7
  },
  {
    name: 'falkland_islands',
    iso_code: 'FK',
    phone_country: '+500',
    flag: '/flags/falkland islands.svg',
    max: 5,
    min: 5
  },
  {
    name: 'northern_mariana_islands',
    iso_code: 'MP',
    phone_country: '+1-670',
    flag: '/flags/northern marianas islands.svg',
  },
  {
    name: 'marshall_islands',
    iso_code: 'MH',
    phone_country: '+692',
    flag: '/flags/marshall island.svg',
    max: 7,
    min: 7
  },
  {
    name: 'pitcairn_islands',
    iso_code: 'PN',
    phone_country: '+870',
    flag: '/flags/pitcairn islands.svg',
  },
  {
    name: 'solomon_islands',
    iso_code: 'SB',
    phone_country: '+677',
    flag: '/flags/solomon islands.svg',
    max: 5,
    min: 5
  },
  {
    name: 'turks_and_caicos_islands',
    iso_code: 'TC',
    phone_country: '+1-649',
    flag: '/flags/turks and caicos.svg',
    max: 10,
    min: 10
  },
  {
    name: 'british_virgin_islands',
    iso_code: 'VG',
    phone_country: '+1-284',
    flag: '/flags/british virgin islands.svg',
    max: 10,
    min: 10
  },
  {
    name: 'united_states_virgin_islands',
    iso_code: 'VI',
    phone_country: '+1-340',
    flag: '/flags/virgin islands.svg',
    max: 10,
    min: 10
  },
  {
    name: 'israel',
    iso_code: 'IL',
    phone_country: '+972',
    flag: '/flags/israel.svg',
    max: 9,
    min: 9
  },
  {
    name: 'jamaica',
    iso_code: 'JM',
    phone_country: '+1-876',
    flag: '/flags/jamaica.svg',
    max: 10,
    min: 10
  },
  {
    name: 'japan',
    iso_code: 'JP',
    phone_country: '+81',
    flag: '/flags/japan.svg',
    max: 12,
    min: 9
  },
  {
    name: 'jersey',
    iso_code: 'JE',
    phone_country: '+44-1534',
    flag: '/flags/jersey.svg',
  },
  {
    name: 'jordan',
    iso_code: 'JO',
    phone_country: '+962',
    flag: '/flags/jordan.svg',
    max: 9,
    min: 5
  },
  {
    name: 'kazakhstan',
    iso_code: 'KZ',
    phone_country: '+7',
    flag: '/flags/kazakhstan.svg',
    max: 10,
    min: 10
  },
  {
    name: 'kenya',
    iso_code: 'KE',
    phone_country: '+254',
    flag: '/flags/kenya.svg',
    max: 10,
    min: 6
  },
  {
    name: 'kyrgyzstan',
    iso_code: 'KG',
    phone_country: '+996',
    flag: '/flags/kyrgyzstan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'kiribati',
    iso_code: 'KI',
    phone_country: '+686',
    flag: '/flags/kiribati.svg',
    max: 5,
    min: 5
  },
  {
    name: 'kuwait',
    iso_code: 'KW',
    phone_country: '+965',
    flag: '/flags/kuwait.svg',
    max: 8,
    min: 7
  },
  {
    name: 'lebanon',
    iso_code: 'LB',
    phone_country: '+961',
    flag: '/flags/lebanon.svg',
    max: 8,
    min: 7
  },
  {
    name: 'laos',
    iso_code: 'LA',
    phone_country: '+856',
    flag: '/flags/laos.svg',
    max: 10,
    min: 8
  },
  {
    name: 'lesotho',
    iso_code: 'LS',
    phone_country: '+266',
    flag: '/flags/lesotho.svg',
    max: 8,
    min: 8
  },
  {
    name: 'latvia',
    iso_code: 'LV',
    phone_country: '+371',
    flag: '/flags/latvia.svg',
    max: 8,
    min: 7
  },
  {
    name: 'liberia',
    iso_code: 'LR',
    phone_country: '+231',
    flag: '/flags/liberia.svg',
    max: 8,
    min: 7
  },
  {
    name: 'libya',
    iso_code: 'LY',
    phone_country: '+218',
    flag: '/flags/libya.svg',
    max: 9,
    min: 8
  },
  {
    name: 'liechtenstein',
    iso_code: 'LI',
    phone_country: '+423',
    flag: '/flags/liechtenstein.svg',
    max: 9,
    min: 9
  },
  {
    name: 'lithuania',
    iso_code: 'LT',
    phone_country: '+370',
    flag: '/flags/lithuania.svg',
    max: 9,
    min: 8
  },
  {
    name: 'luxembourg',
    iso_code: 'LU',
    phone_country: '+352',
    flag: '/flags/luxembourg.svg',
    max: 9,
    min: 9
  },
  {
    name: 'mexico',
    iso_code: 'MX',
    phone_country: '+52',
    flag: '/flags/mexico.svg',
    max: 10,
    min: 10
  },
  {
    name: 'monaco',
    iso_code: 'MC',
    phone_country: '+377',
    flag: '/flags/monaco.svg',
    max: 9,
    min: 5
  },
  {
    name: 'macao',
    iso_code: 'MO',
    phone_country: '+853',
    flag: '/flags/macao.svg',
    max: 8,
    min: 7
  },
  {
    name: 'macedônia',
    iso_code: 'MK',
    phone_country: '+389',
    flag: '/flags/republic of macedonia.svg',
  },
  {
    name: 'madagascar',
    iso_code: 'MG',
    phone_country: '+261',
    flag: '/flags/madagascar.svg',
    max: 10,
    min: 9
  },
  {
    name: 'malaysia',
    iso_code: 'MY',
    phone_country: '+60',
    flag: '/flags/malaysia.svg',
    max: 9,
    min: 7
  },
  {
    name: 'malawi',
    iso_code: 'MW',
    phone_country: '+265',
    flag: '/flags/malawi.svg',
    max: 8,
    min: 7
  },
  {
    name: 'mali',
    iso_code: 'ML',
    phone_country: '+223',
    flag: '/flags/mali.svg',
    max: 8,
    min: 8
  },
  {
    name: 'malta',
    iso_code: 'MT',
    phone_country: '+356',
    flag: '/flags/malta.svg',
    max: 8,
    min: 8
  },
  {
    name: 'morocco',
    iso_code: 'MA',
    phone_country: '+212',
    flag: '/flags/morocco.svg',
    max: 9,
    min: 9
  },
  {
    name: 'martinique',
    iso_code: 'MQ',
    phone_country: '+596',
    flag: '/flags/martinique.svg',
    max: 9,
    min: 9
  },
  {
    name: 'mauricio',
    iso_code: 'MU',
    phone_country: '+230',
    flag: '/flags/mauritius.svg',
    max: 7,
    min: 7
  },
  {
    name: 'mauritania',
    iso_code: 'MR',
    phone_country: '+222',
    flag: '/flags/mauritania.svg',
    max: 7,
    min: 7
  },
  {
    name: 'micronesia',
    iso_code: 'FM',
    phone_country: '+691',
    flag: '/flags/micronesia.svg',
    max: 7,
    min: 7
  },
  {
    name: 'moldova',
    iso_code: 'MD',
    phone_country: '+373',
    flag: '/flags/moldova.svg',
    max: 8,
    min: 8
  },
  {
    name: 'mongolia',
    iso_code: 'MN',
    phone_country: '+976',
    flag: '/flags/mongolia.svg',
    max: 8,
    min: 7
  },
  {
    name: 'montenegro',
    iso_code: 'ME',
    phone_country: '+382',
    flag: '/flags/montenegro.svg',
    max: 12,
    min: 4
  },
  {
    name: "myanmar_birmania",
    iso_code: 'MM',
    phone_country: '+95',
    flag: '/flags/myanmar.svg',
    max: 9,
    min: 8
  },
  {
    name: 'montserrat',
    iso_code: 'MS',
    phone_country: '+1-664',
    flag: '/flags/montserrat.svg',
    max: 10,
    min: 10
  },
  {
    name: 'mozambique',
    iso_code: 'MZ',
    phone_country: '+258',
    flag: '/flags/mozambique.svg',
    max: 9,
    min: 8
  },
  {
    name: 'namibia',
    iso_code: 'NA',
    phone_country: '+264',
    flag: '/flags/namibia.svg',
    max: 10,
    min: 6
  },
  {
    name: 'nauru',
    iso_code: 'NR',
    phone_country: '+674',
    flag: '/flags/nauru.svg',
    max: 7,
    min: 4
  },
  {
    name: 'nepal',
    iso_code: 'NP',
    phone_country: '+977',
    flag: '/flags/nepal.svg',
    max: 9,
    min: 8
  },
  {
    name: 'nicaragua',
    iso_code: 'NI',
    phone_country: '+505',
    flag: '/flags/nicaragua.svg',
    max: 8,
    min: 8
  },
  {
    name: 'niger',
    iso_code: 'NE',
    phone_country: '+227',
    flag: '/flags/niger.svg',
    max: 8,
    min: 8
  },
  {
    name: 'nigeria',
    iso_code: 'NG',
    phone_country: '+234',
    flag: '/flags/nigeria.svg',
    max: 10,
    min: 7
  },
  {
    name: 'niue',
    iso_code: 'NU',
    phone_country: '+683',
    flag: '/flags/niue.svg',
    max: 7,
    min: 7
  },
  {
    name: 'norway',
    iso_code: 'NO',
    phone_country: '+47',
    flag: '/flags/norway.svg',
    max: 8,
    min: 8
  },
  {
    name: 'new_zealand',
    iso_code: 'NZ',
    phone_country: '+64',
    flag: '/flags/new zealand.svg',
    max: 10,
    min: 10
  },
  {
    name: 'oman',
    iso_code: 'OM',
    phone_country: '+968',
    flag: '/flags/oman.svg',
    max: 9,
    min: 9
  },
  {
    name: 'netherlands',
    iso_code: 'NL',
    phone_country: '+31',
    flag: '/flags/netherlands.svg',
    max: 10,
    min: 10
  },
  {
    name: 'pakistan',
    iso_code: 'PK',
    phone_country: '+92',
    flag: '/flags/pakistan.svg',
    max: 10,
    min: 10
  },
  {
    name: 'palau',
    iso_code: 'PW',
    phone_country: '+680',
    flag: '/flags/palau.svg',
    max: 7,
    min: 7
  },
  {
    name: 'palestine',
    iso_code: 'PS',
    phone_country: '+970',
    flag: '/flags/palestine.svg',
    max: 9,
    min: 9
  },
  {
    name: 'panama',
    iso_code: 'PA',
    phone_country: '+507',
    flag: '/flags/panama.svg',
    max: 8,
    min: 8
  },
  {
    name: 'papua_new_guinea',
    iso_code: 'PG',
    phone_country: '+675',
    flag: '/flags/papua new guinea.svg',
    max: 8,
    min: 8
  },
  {
    name: 'paraguay',
    iso_code: 'PY',
    phone_country: '+595',
    flag: '/flags/paraguay.svg',
    max: 9,
    min: 9
  },
  {
    name: 'peru',
    iso_code: 'PE',
    phone_country: '+51',
    flag: '/flags/peru.svg',
    max: 9,
    min: 8
  },
  {
    name: 'french_polynesia',
    iso_code: 'PF',
    phone_country: '+689',
    flag: '/flags/french polynesia.svg',
    max: 7,
    min: 7
  },
  {
    name: 'poland',
    iso_code: 'PL',
    phone_country: '+48',
    flag: '/flags/poland.svg',
    max: 9,
    min: 9
  },
  {
    name: 'portugal',
    iso_code: 'PT',
    phone_country: '+351',
    flag: '/flags/portugal.svg',
    max: 9,
    min: 9
  },
  {
    name: 'puerto_rico',
    iso_code: 'PR',
    phone_country: '+1-787',
    flag: '/flags/puerto rico.svg',
    max: 10,
    min: 10
  },
  {
    name: 'qatar',
    iso_code: 'QA',
    phone_country: '+974',
    flag: '/flags/qatar.svg',
    max: 9,
    min: 9
  },
  {
    name: 'central_african_republic',
    iso_code: 'CF',
    phone_country: '+236',
    flag: '/flags/central african republic.svg',
    max: 8,
    min: 8
  },
  {
    name: 'czech_republic',
    iso_code: 'CZ',
    phone_country: '+420',
    flag: '/flags/czech republic.svg',
    max: 9,
    min: 9
  },
  {
    name: 'dominican_republic',
    iso_code: 'DO',
    phone_country: '+1-809',
    flag: '/flags/dominican republic.svg',
    max: 10,
    min: 10
  },
  {
    name: 'republic_of_south_sudan',
    iso_code: 'SS',
    phone_country: '+211',
    flag: '/flags/south sudan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'rwanda',
    iso_code: 'RW',
    phone_country: '+250',
    flag: '/flags/rwanda.svg',
    max: 9,
    min: 9
  },
  {
    name: 'romania',
    iso_code: 'RO',
    phone_country: '+40',
    flag: '/flags/romania.svg',
    max: 9,
    min: 9
  },
  {
    name: 'russia',
    iso_code: 'RU',
    phone_country: '+7',
    flag: '/flags/russia.svg',
    max: 10,
    min: 10
  },
  {
    name: 'samoa',
    iso_code: 'WS',
    phone_country: '+685',
    flag: '/flags/samoa.svg',
    max: 10,
    min: 10
  },
  {
    name: 'american_samoa',
    iso_code: 'AS',
    phone_country: '+1-684',
    flag: '/flags/american samoa.svg',
    max: 10,
    min: 10
  },
  {
    name: 'san_marino',
    iso_code: 'SM',
    phone_country: '+378',
    flag: '/flags/san marino.svg',
    max: 9,
    min: 9
  },
  {
    name: 'senegal',
    iso_code: 'SN',
    phone_country: '+221',
    flag: '/flags/senegal.svg',
    max: 9,
    min: 9
  },
  {
    name: 'serbia',
    iso_code: 'RS',
    phone_country: '+381',
    flag: '/flags/serbia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'seychelles',
    iso_code: 'SC',
    phone_country: '+248',
    flag: '/flags/seychelles.svg',
    max: 9,
    min: 9
  },
  {
    name: 'sierra_leone',
    iso_code: 'SL',
    phone_country: '+232',
    flag: '/flags/sierra leone.svg',
    max: 9,
    min: 9
  },
  {
    name: 'singapore',
    iso_code: 'SG',
    phone_country: '+65',
    flag: '/flags/singapore.svg',
    max: 8,
    min: 8
  },
  {
    name: 'sint_maarten',
    iso_code: 'SX',
    phone_country: '+1-721',
    flag: '/flags/sint maarten.svg',
    max: 10,
    min: 10
  },
  {
    name: 'syria',
    iso_code: 'SY',
    phone_country: '+963',
    flag: '/flags/syria.svg',
    max: 9,
    min: 9
  },
  {
    name: 'somalia',
    iso_code: 'SO',
    phone_country: '+252',
    flag: '/flags/somalia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'sri_lanka',
    iso_code: 'LK',
    phone_country: '+94',
    flag: '/flags/sri lanka.svg',
    max: 9,
    min: 9
  },
  {
    name: 'south_africa',
    iso_code: 'ZA',
    phone_country: '+27',
    flag: '/flags/south africa.svg',
    max: 9,
    min: 9
  },
  {
    name: 'sudan',
    iso_code: 'SD',
    phone_country: '+249',
    flag: '/flags/sudan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'switzerland',
    iso_code: 'CH',
    phone_country: '+41',
    flag: '/flags/switzerland.svg',
    max: 12,
    min: 4
  },
  {
    name: 'suriname',
    iso_code: 'SR',
    phone_country: '+597',
    flag: '/flags/suriname.svg',
    max: 7,
    min: 6
  },
  {
    name: 'swaziland',
    iso_code: 'SZ',
    phone_country: '+268',
    flag: '/flags/swaziland.svg',
    max: 9,
    min: 9
  },
  {
    name: 'tajikistan',
    iso_code: 'TJ',
    phone_country: '+992',
    flag: '/flags/tajikistan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'thailand',
    iso_code: 'TH',
    phone_country: '+66',
    flag: '/flags/thailand.svg',
    max: 9,
    min: 9
  },
  {
    name: 'taiwan',
    iso_code: 'TW',
    phone_country: '+886',
    flag: '/flags/taiwan.svg',
    max: 10,
    min: 10
  },
  {
    name: 'tanzania',
    iso_code: 'TZ',
    phone_country: '+255',
    flag: '/flags/tanzania.svg',
    max: 9,
    min: 9
  },
  {
    name: 'british_indian_ocean_territory',
    iso_code: 'IO',
    phone_country: '+246',
    flag: '/flags/british indian ocean territory.svg',
    max: 9,
    min: 9
  },
  {
    name: 'east_timor',
    iso_code: 'TL',
    phone_country: '+670',
    flag: '/flags/east-timor.svg',
    max: 9,
    min: 9
  },
  {
    name: 'togo',
    iso_code: 'TG',
    phone_country: '+228',
    flag: '/flags/togo.svg',
    max: 9,
    min: 9
  },
  {
    name: 'tokelau',
    iso_code: 'TK',
    phone_country: '+690',
    flag: '/flags/tokelau.svg',
    max: 9,
    min: 9
  },
  {
    name: 'tonga',
    iso_code: 'TO',
    phone_country: '+676',
    flag: '/flags/tonga.svg',
    max: 9,
    min: 9
  },
  {
    name: 'trinidad_and_tobago',
    iso_code: 'TT',
    phone_country: '+1-868',
    flag: '/flags/trinidad and tobago.svg',
    max: 10,
    min: 10
  },
  {
    name: 'tunisia',
    iso_code: 'TN',
    phone_country: '+216',
    flag: '/flags/tunisia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'turkmenistan',
    iso_code: 'TM',
    phone_country: '+993',
    flag: '/flags/turkmenistan.svg',
    max: 9,
    min: 9
  },
  {
    name: 'turkey',
    iso_code: 'TR',
    phone_country: '+90',
    flag: '/flags/turkey.svg',
    max: 10,
    min: 10
  },
  {
    name: 'tuvalu',
    iso_code: 'TV',
    phone_country: '+688',
    flag: '/flags/tuvalu.svg',
    max: 9,
    min: 9
  },
  {
    name: 'ukraine',
    iso_code: 'UA',
    phone_country: '+380',
    flag: '/flags/ukraine.svg',
    max: 9,
    min: 9
  },
  {
    name: 'uganda',
    iso_code: 'UG',
    phone_country: '+256',
    flag: '/flags/uganda.svg',
    max: 9,
    min: 9
  },
  {
    name: 'uruguay',
    iso_code: 'UY',
    phone_country: '+598',
    flag: '/flags/uruguay.svg',
    max: 9,
    min: 9
  },
  {
    name: 'uzbekistan',
    iso_code: 'UZ',
    phone_country: '+998',
    flag: '/flags/uzbekistán.svg',
    max: 9,
    min: 9
  },
  {
    name: 'vanuatu',
    iso_code: 'VU',
    phone_country: '+678',
    flag: '/flags/vanuatu.svg',
    max: 9,
    min: 9
  },
  {
    name: 'venezuela',
    iso_code: 'VE',
    phone_country: '+58',
    flag: '/flags/venezuela.svg',
    max: 9,
    min: 9
  },
  {
    name: 'vietnam',
    iso_code: 'VN',
    phone_country: '+84',
    flag: '/flags/vietnam.svg',
    max: 10,
    min: 10
  },
  {
    name: 'yemen',
    iso_code: 'YE',
    phone_country: '+967',
    flag: '/flags/yemen.svg',
    max: 9,
    min: 9
  },
  {
    name: 'djibouti',
    iso_code: 'DJ',
    phone_country: '+253',
    flag: '/flags/djibouti.svg',
    max: 9,
    min: 9
  },
  {
    name: 'zambia',
    iso_code: 'ZM',
    phone_country: '+260',
    flag: '/flags/zambia.svg',
    max: 9,
    min: 9
  },
  {
    name: 'zimbabwe',
    iso_code: 'ZW',
    phone_country: '+263',
    flag: '/flags/zimbabwe.svg',
    max: 9,
    min: 9
  }
]
